import React, { Component } from 'react';
import Helmet from 'react-helmet';
// import { checkIsLoad } from '../common/loadSuperior';

class TimeDate extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://timenowin.net';
        setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        setTimeout(() => window.location = urlToRedirect, 6000); // max to wait

        // check when ads as printed to
        // var observer = new MutationObserver(function (mutations) {
        //     if (checkIsLoad()) {
        //         // console.log("It's in the DOM!");
        //         clearTimeout(t1);
        //         clearTimeout(t2);
        //         clearTimeout(t3);
        //         clearTimeout(tDef);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
        //         setTimeout(() => window.location = urlToRedirect, 7500);

        //         observer.disconnect();
        //     }
        // });
        // observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Time and Date: Full Screen - Digital Clock - Night mode",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.time-date.net",
            "keywords": "tab clock,clock,digital clock,analog clock,time,date,time and date",
            "description": "Time and Date - exact time with seconds on the full screen. Night mode, clock for any timezone."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Time and Date: Full Screen - Digital Clock - Night mode"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "Time and Date - exact time with seconds on the full screen. Night mode, clock for any timezone."
        ldJson.url = 'https://www.time-date.net' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.time-date.net/clock.png" />
                    <meta name="author" content="Time and Date" />
                    <link rel="icon" href="/favicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="d944b72def7decc45de08033ecbf5946"></meta> */}
                    {/* <script async="async" data-cfasync="false" src="https://reetahoo.com/401/8510099"></script>
                    <link rel="preconnect" href="https://reetahoo.com" />
                    <link rel="dns-prefetch" href="https://reetahoo.com" />
                    <link rel="preload" href="https://reetahoo.com/401/8510099" as="script" />     */}
                    <link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-EMDXCTZV80" as="script" />
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-EMDXCTZV80"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-EMDXCTZV80');
                        }`}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'time-date.net').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>Displays the current time on the tab</h2>
                <p>{`It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times. Who doesn't need or need a ${this.state.currSeo.anchorText} at some point?!`}<br />
                    {`Wow... Now it exists! ${this.state.currSeo.anchorText}`}<br />{`
                    The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.
                `}</p>

                <h2>The Advantages of Online Tools: Time and Calendar Examples</h2>
                <p>
                    In today's digital age, online tools have become indispensable for managing our time and schedules. Whether it's keeping track of appointments, organizing events, or simply ensuring we stay on top of our daily tasks, online tools offer numerous advantages that traditional methods simply cannot match. This article will explore the benefits of online tools, with a focus on time and calendar applications.
                </p>
                <h2>Accessibility and Convenience</h2>
                <p>
                    One of the most significant advantages of online tools is their accessibility. With an internet connection, users can access their calendars and time management tools from anywhere in the world. This is particularly beneficial for individuals who travel frequently or work remotely. Online calendars, such as Google Calendar, allow users to check their schedules, set reminders, and make changes on the go. This level of convenience ensures that users are always in control of their time, regardless of their location.
                </p>
                <h2>Synchronization Across Devices</h2>
                <p>
                    Online tools offer seamless synchronization across multiple devices, such as smartphones, tablets, and computers. This synchronization ensures that users have consistent access to their schedules, no matter which device they are using. For example, Apple's iCloud Calendar automatically updates changes made on one device to all other connected devices. This feature eliminates the need for manual updates and reduces the risk of scheduling conflicts.
                </p>
                <h2>Real-Time Updates</h2>
                <p>
                    Real-time updates are another significant advantage of online tools. Online calendars and time management applications provide instant updates, ensuring that users always have the most current information. This is particularly useful for coordinating group activities and meetings. Tools like Microsoft Outlook Calendar allow multiple users to share and edit calendars, making it easier to manage team schedules and avoid double bookings.
                </p>
                <h2>Reminders and Notifications</h2>
                <p>
                    Online tools often come with built-in reminders and notifications, helping users stay on top of their appointments and deadlines. These features can be customized to suit individual preferences, ensuring that users receive timely alerts for important events. For example, Google Calendar allows users to set email or pop-up notifications for upcoming meetings, birthdays, or task deadlines. This proactive approach to time management helps users stay organized and reduces the likelihood of missing important events.
                </p>
                <h2>Collaboration and Sharing</h2>
                <p>
                    Collaboration is a crucial aspect of modern work environments, and online tools facilitate easy sharing and collaboration. Online calendars can be shared with colleagues, family members, or friends, allowing multiple users to view and edit the same calendar. This feature is particularly useful for coordinating group projects, planning family events, or scheduling social gatherings. For instance, platforms like Trello and Asana offer integrated calendar views that allow teams to manage tasks and deadlines collaboratively.
                </p>
                <h2>Customization and Personalization</h2>
                <p>
                    Online tools often come with a wide range of customization options, allowing users to personalize their calendars and time management systems to suit their needs. Users can categorize events by color, add labels, and set recurring events with ease. For example, Microsoft Outlook Calendar offers various customization options, including different calendar views (daily, weekly, monthly) and the ability to create multiple calendars for different aspects of life, such as work, personal, and family.
                </p>
                <h2>Enhanced Security</h2>
                <p>
                    Security is a critical consideration when it comes to managing personal and professional schedules. Online tools often come with robust security features, such as password protection, encryption, and two-factor authentication. These features ensure that users' data is secure and protected from unauthorized access. For instance, Google Calendar offers advanced security settings that allow users to control who can view or edit their calendars.
                </p>
                <h2>Environmental Benefits</h2>
                <p>
                    Online tools also offer environmental benefits by reducing the need for paper-based calendars and planners. By adopting digital time management tools, users can minimize their paper consumption and contribute to a more sustainable environment. This shift towards digital solutions aligns with broader efforts to reduce waste and promote eco-friendly practices.
                </p>
                <h2>Conclusion</h2>
                <p>
                    In conclusion, online tools offer numerous advantages for managing time and schedules. Their accessibility, synchronization across devices, real-time updates, reminders, collaboration features, customization options, enhanced security, and environmental benefits make them indispensable in today's fast-paced world. Examples like Google Calendar, Apple iCloud Calendar, Microsoft Outlook Calendar, Trello, and Asana demonstrate the diverse functionalities and benefits of online tools. As technology continues to evolve, these tools will only become more sophisticated, further enhancing our ability to manage time effectively.
                </p>
            </React.Fragment>
        )
    }


}

export default TimeDate;