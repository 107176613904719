import React, { Component } from 'react';
import Helmet from 'react-helmet';

class TimerLat extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://online-timer.me/';
        setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        setTimeout(() => window.location = urlToRedirect, 7000); // max to wait

        // check when ads as printed to
        // var observer = new MutationObserver(function (mutations) {
        //     if (document.getElementById('p_7967215')) {
        //         //console.log("It's in the DOM!");
        //         clearTimeout(t1);
        //         clearTimeout(t2);
        //         clearTimeout(t3);
        //         clearTimeout(tDef);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
        //         setTimeout(() => window.location = urlToRedirect, 7500);
        //         observer.disconnect();
        //     }
        // });
        // observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Online Timer - Countdown - Easy to Use",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.timer.lat",
            "keywords": "timer,online timer,countdown,countdonw timer",
            "description": "A Free Countdown Timer - This timer is perfect for classrooms! Simple and easy with ready-to-use presets."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Online Timer - Countdown - Easy to Use"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "A Free Countdown Timer - This timer is perfect for classrooms! Simple and easy with ready-to-use presets."
        ldJson.url = 'https://www.timer.lat' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.timer.lat/timer.png" />
                    <meta name="author" content="timer.lat" />
                    <link rel="icon" href="/timerFavicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="682c8a681b6668f508823caa50d0edb1"></meta> */}
                    {/* <link rel="preconnect" href="https://reetahoo.com" /> */}
                    {/* <link rel="dns-prefetch" href="https://reetahoo.com" /> */}
                    {/* <script async="async" data-cfasync="false" src="https://reetahoo.com/401/8510145"></script> */}
                    {/* <link rel="preload" href="https://reetahoo.com/401/8510145" as="script" /> */}
                    <link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-SCKD1LJSWG" as="script" />
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-SCKD1LJSWG"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-SCKD1LJSWG');
                        }`}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'timer.lat').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>Great tool for keeping track and marking time</h2>
                <p>
                    {this.state.currSeo.textBody ?
                        this.state.currSeo.textBody :
                        `Great tool for keeping track and marking time, very useful for various applications. It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times.`
                        + ` Who doesn't need or needed a ${this.state.currSeo.anchorText} at some point?! `
                        + `Wow... Now it exists! ${this.state.currSeo.anchorText} The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.`
                    }
                </p>
                <h2>Advantages of Online Timer Tools: Practical Use Cases</h2>
                <p>
                    In the fast-paced world we live in, managing time efficiently has become a crucial skill. Online timer tools have emerged as essential aids for improving productivity and organization. These tools offer various benefits and practical applications that cater to both personal and professional needs. This article explores the advantages of online timer tools and provides examples of how they can be used in different scenarios.
                </p>
                <h2>Enhanced Productivity</h2>
                <p>
                    One of the most significant advantages of online timer tools is their ability to enhance productivity. By setting specific time limits for tasks, users can maintain focus and avoid distractions. For example, the Pomodoro Technique, a popular time management method, involves working for 25-minute intervals followed by short breaks. Online timers like TomatoTimer and Pomodone allow users to implement this technique easily, helping them stay on track and manage their time more effectively.
                </p>
                <h2>Task Management and Organization</h2>
                <p>
                    Online timer tools can also assist in task management and organization. Users can create time blocks for different activities, ensuring that they allocate sufficient time for each task. Tools like Toggl and Clockify offer features that allow users to track time spent on various tasks and generate reports. This information can be invaluable for identifying time-wasting activities and optimizing workflows.
                </p>
                <h2>Meeting Deadlines</h2>
                <p>
                    Meeting deadlines is crucial in both personal and professional settings. Online timers help users set reminders and countdowns for upcoming deadlines, ensuring they stay on schedule. For instance, tools like Countdown Timer and TickCounter allow users to set countdowns for project deadlines, exams, or important events. These reminders can be customized to send notifications, helping users stay aware of approaching deadlines and avoid last-minute rushes.
                </p>
                <h2>Improved Focus and Concentration</h2>
                <p>
                    Distractions are a common challenge in today's digital age. Online timer tools can help users improve their focus and concentration by creating dedicated time slots for uninterrupted work. Tools like Focus Booster and Be Focused enable users to set specific intervals for focused work and short breaks. This approach helps in maintaining mental clarity and reducing the impact of distractions, leading to higher productivity levels.
                </p>
                <h2>Health and Wellness</h2>
                <p>
                    Online timers can also contribute to health and wellness by encouraging regular breaks and promoting healthy work habits. Prolonged periods of sitting can have adverse effects on health, and online timers like Stretchly and WorkRave remind users to take short breaks, stretch, and move around. These tools help prevent burnout and promote a healthier work-life balance.
                </p>
                <h2>Practical Use Cases</h2>
                <p>
                    <strong>Freelancers and Remote Workers:</strong> Freelancers and remote workers often juggle multiple projects and deadlines. Online timers help them allocate time for each project, track billable hours, and meet client deadlines efficiently.
                </p>
                <p>
                    <strong>Students:</strong> Students can benefit from online timers by using them to manage study sessions and break times. Tools like StudyTimer and MyStudyLife allow students to create study schedules, set reminders for assignments, and track their progress.
                </p>
                <p>
                    <strong>Fitness Enthusiasts:</strong> Fitness enthusiasts can use online timers to time their workouts, set intervals for exercises, and track rest periods. Tools like Interval Timer and GymBoss offer customizable timers for various workout routines, helping users stay on track with their fitness goals.
                </p>
                <p>
                    <strong>Event Planners:</strong> Event planners can use online timers to manage event schedules, coordinate activities, and ensure timely execution. Tools like Event Timer and ChronoTrack provide countdowns and reminders for different stages of event planning, helping organizers stay organized and efficient.
                </p>
                <h2>Conclusion</h2>
                <p>
                    In conclusion, online timer tools offer numerous advantages for managing time, enhancing productivity, and improving organization. Their practical applications extend to various personal and professional scenarios, from meeting deadlines to promoting health and wellness. Examples like TomatoTimer, Pomodone, Toggl, Countdown Timer, Focus Booster, and Stretchly demonstrate the diverse functionalities and benefits of online timer tools. As technology continues to evolve, these tools will become even more sophisticated, further empowering users to manage their time effectively and achieve their goals.
                </p>
            </React.Fragment>
        )
    }


}

export default TimerLat;