import React, { Component } from 'react';
import Helmet from 'react-helmet';
// import { checkIsLoad } from '../common/loadSuperior';

class TimeTimeSite extends Component {

    state = {
        'currSeo': this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname).length !== 0 ?
            this.props.seoLinks.filter((seo) => seo.path === this.props.location.pathname)[0] :
            this.props.seoLinks[0]
    }

    componentDidMount() {
        let urlToRedirect = this.state.currSeo.urlToSend ? this.state.currSeo.urlToSend : 'https://timenowin.net';
        let t1 = setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 1500);
        let t2 = setTimeout(() => document.getElementById('wait').innerText = 'loading...', 2700);
        let t3 = setTimeout(() => document.getElementById('wait').innerText = 'wait...', 3800);
        setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 5800);
        setTimeout(() => document.getElementById('wait').innerText = 'wait.......', 7800);
        setTimeout(() => document.getElementById('wait').innerText = 'wait.........', 9800);
        setTimeout(() => document.getElementById('wait').innerText = 'connecting...', 11800);
        setTimeout(() => document.getElementById('wait').innerText = 'connecting.....', 13800);
        setTimeout(() => document.getElementById('wait').innerText = 'wait...', 14800);
        let tDef = setTimeout(() => window.location = urlToRedirect, 15000); // max to wait

        // check when ads as printed to
        // var observer = new MutationObserver(function (mutations) {
        //     if (checkIsLoad()) {
        //         // console.log("It's in the DOM!");
        //         clearTimeout(t1);
        //         clearTimeout(t2);
        //         clearTimeout(t3);
        //         clearTimeout(tDef);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.', 1500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...', 2600);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait.....', 4500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait........', 6500);
        //         setTimeout(() => document.getElementById('wait').innerText = 'wait...........', 7500);
        //         setTimeout(() => window.location = urlToRedirect, 7500);

        //         observer.disconnect();
        //     }
        // });
        // observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    }

    render() {
        const ldJson = {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Time Site: Full Screen - Digital Clock - Night mode",
            "applicationCategory": "BrowserApplication",
            "browserRequirements": "Requires JavaScript. Requires HTML5.",
            "operatingSystem": "All",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "url": "https://www.timetime.site",
            "keywords": "tab clock,clock,digital clock,analog clock",
            "description": "Time Site - exact time with seconds on the full screen. Night mode, clock for any timezone."
        }


        ldJson.name = this.state.currSeo.title ? this.state.currSeo.title : "Time Site: Full Screen - Digital Clock - Night mode"
        ldJson.description = this.state.currSeo.description ? this.state.currSeo.description : "Time Site - exact time with seconds on the full screen. Night mode, clock for any timezone."
        ldJson.url = 'https://www.timetime.site' + this.props.location.pathname;

        return (
            <React.Fragment>
                <Helmet>
                    <html lang={this.props.location.pathname.split('/')[2] ? this.props.location.pathname.split('/')[2] : 'en'} />
                    <title>{ldJson.name}</title>
                    <meta name="description" content={ldJson.description} />
                    <meta property="og:description" content={ldJson.description} />
                    <meta property="og:image" content="https://www.timetime.site/clock.png" />
                    <meta name="author" content="Time Site" />
                    <link rel="icon" href="/favicon.ico" />
                    <meta name="keywords" content={ldJson.keywords} />
                    {/* <meta name="monetag" content="d944b72def7decc45de08033ecbf5946"></meta> */}
                    {/* <script async="async" data-cfasync="false" src="https://reetahoo.com/401/8510099"></script>
                    <link rel="preconnect" href="https://reetahoo.com" />
                    <link rel="dns-prefetch" href="https://reetahoo.com" />
                    <link rel="preload" href="https://reetahoo.com/401/8510099" as="script" />     */}
                    <link rel="preload" href="https://www.googletagmanager.com/gtag/js?id=G-3KVJ0RRVFL" as="script" />
                    <link rel="canonical" href={ldJson.url} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-3KVJ0RRVFL"></script>
                    <script>
                        {`var host = window.location.hostname;
                        if (host != "localhost") {
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('config', 'G-3KVJ0RRVFL');
                        }`}
                    </script>
                </Helmet>
                <img src="/spinner.gif" alt="loading" width="20" height="20" /><div id="wait" style={{ 'color': '#000' }}></div>
                <ul>
                    {this.props.seoLinks.filter(seo => seo.domain === 'timetime.site').map((seo, idx) =>
                        <li key={idx}><a href={`${seo.path}`} title={seo.anchorText}>{seo.anchorText}</a></li>)}
                </ul>

                <h1>{this.state.currSeo.anchorText}</h1>
                <h2>Displays the current time on the tab</h2>
                <p>{`It's always like this, when we need something we never have one nearby, which ends up being very frustrating at times. Who doesn't need or need a ${this.state.currSeo.anchorText} at some point?!`}<br />
                    {`Wow... Now it exists! ${this.state.currSeo.anchorText}`}<br />{`
                    The best and most complete ${this.state.currSeo.anchorText} is simple, fast and easy to use, always available when you need it.
                `}</p>
                <h2>Time and Online Clocks</h2>
                <p>
                    Time is a fundamental aspect of our lives, governing everything from our daily routines to the larger cycles of nature and the cosmos. The concept of time has been a subject of fascination and study for centuries, with various cultures developing their own methods of measuring and understanding it. Today, with the advent of digital technology and the internet, timekeeping has reached new levels of precision and accessibility.
                </p>
                <h2>The Concept of Time</h2>
                <p>
                    Time is a continuous sequence of events that occur in an apparently irreversible succession from the past, through the present, and into the future. It is a concept that has puzzled philosophers, scientists, and thinkers throughout history. The measurement of time began with the observation of natural phenomena, such as the movement of the sun, moon, and stars. Early humans used these celestial bodies to develop calendars and track the passage of days and seasons.
                </p>
                <h2>The Evolution of Clocks</h2>
                <p>
                    The invention of clocks marked a significant advancement in humanity's ability to measure and keep track of time. Early clocks, such as sundials and water clocks, relied on the position of the sun or the flow of water to indicate the time. These methods, while innovative, were limited by their reliance on specific environmental conditions.
                </p>
                <p>
                    The development of mechanical clocks in the Middle Ages brought about a revolution in timekeeping. These clocks used intricate mechanisms of gears and escapements to maintain a consistent measure of time. The introduction of the pendulum clock in the 17th century, invented by Christiaan Huygens, further improved the accuracy of timekeeping. Pendulum clocks became the standard for accurate time measurement for centuries.
                </p>
                <h2>Modern Clocks and Digital Timekeeping</h2>
                <p>
                    In the modern era, clocks have become ubiquitous and highly accurate. The invention of the quartz clock in the 20th century brought even greater precision, as quartz crystals can oscillate at a very consistent frequency. Today, digital clocks and electronic timekeeping devices are commonplace, found in everything from wristwatches to smartphones.
                </p>
                <p>
                    The internet has further transformed timekeeping with the advent of online clocks. Online clocks provide accurate and easily accessible time information to users around the world. These clocks are synchronized with atomic clocks, which are the most precise timekeeping devices available. Atomic clocks use the vibrations of atoms, typically cesium or rubidium, to measure time with incredible accuracy.
                </p>
                <h2>Uses of Online Clocks</h2>
                <p>
                    Online clocks serve a variety of purposes. They help people keep track of time zones, coordinate international meetings, and manage deadlines. Businesses rely on online clocks to ensure timely operations, and travelers use them to plan their journeys across different time zones. Online countdown timers and alarms are also popular tools for managing time effectively.
                </p>
                <p>
                    One of the significant advantages of online clocks is their accessibility. Anyone with an internet connection can access accurate time information from anywhere in the world. This accessibility has made online clocks an indispensable tool in our increasingly interconnected and globalized world.
                </p>
                <h2>Conclusion</h2>
                <p>
                    In conclusion, time and clocks are essential components of human life, influencing everything from our daily routines to the broader rhythms of nature and the universe. The evolution of clocks, from ancient sundials to modern digital and online timekeeping devices, reflects humanity's ongoing quest for precision and accuracy in measuring time. With the advent of the internet, online clocks have become invaluable tools, providing reliable and accessible time information to users around the globe. As technology continues to advance, the way we measure and understand time will undoubtedly continue to evolve.
                </p>
            </React.Fragment>
        )
    }


}

export default TimeTimeSite;